var previousTop = 0;
function initParallaxElements(){
    if ($('body').innerWidth() > 960) {
        initstickyParallax('[data-parallax]');
    } else {
        initstickyParallax('[data-parallax-mobile="true"]');
    }
}

function initstickyParallax($elements) {
    stickyParallaxHolder($elements);

    window.addEventListener('scroll', function(){
        stickParallax($elements);
    }, {
        capture: true,
        passive: true
    });


    $(window).resized(function () {
        stickyParallaxHolder($elements);
    });
}
function stickyParallaxHolder($elements) {
    $($elements).each(function (index, el) {
        var $element = $(el);
        var data = $element.data("parallax");
        var axis = data['axis'];

        if (axis == "x") {
            var fixed = data['fixed'];
            if (fixed) {
                $element.parent().height($element.height());
            }
        } else if (axis == "y") {
            var newHeight = $element.outerHeight(true);
            var stickyCont = $('.parallaxParent');

            if ($element.parent('.parallaxParent').length > 0) {
                stickyCont.css('height', newHeight + "px");
            } else {
                $element.wrap("<div class='parallaxParent'></div>");
                stickyCont = $element.parent('.parallaxParent');
                stickyCont.css('height', (newHeight + "px"));
            }
        }
    });
}
function stickParallax($elements) {
    //HANDLE SCROLL POSITION
    var currentTop = $(document).scrollTop();
    if (currentTop != previousTop) {
        setStickPosition($elements);
    }

    previousTop = currentTop;
}
function setStickPosition($elements){
    var currentTop = $(document).scrollTop();
    $($elements).each(function (index, el) {
        var $element = $(el);
        var data = $element.data("parallax");
        var axis = data['axis'];

        if (axis == "path") {
            var scrollTill = ($(this).parents('.row-inner').offset().top + $(this).parents('.row-inner').height());
            var totalDash = parseInt($element.css('stroke-dasharray'));

            var percentScroll = (currentTop * 100) / scrollTill;
            var percentDash = (percentScroll * totalDash) / 100;
            var delta = percentDash;

            if(delta<0) delta = 0;

            TweenLite.set($element, {
                strokeDashoffset: delta
            });
        } else if (axis == "x") {
            var fixed = data['fixed'];
            var direction = data['direction'];

            var scrollTill = ($(this).parents('.row-inner').offset().top + $(this).parents('.row-inner').height());
            var totalWidth = $element.outerWidth();

            if (fixed == "true") {
                scrollTill = ($('footer.site-footer').offset().top - $element.outerHeight()) - (($(window).height() / 2) - $element.outerHeight());
                totalWidth = $('body').innerWidth() + $element.outerWidth();
            }

            var percentScroll = (currentTop * 100) / scrollTill;
            var percentWidth = (percentScroll * totalWidth) / 100;
            var delta = percentWidth;

            if (fixed == "true") {
                delta = (percentWidth - $element.outerWidth());
            }

            var y = fixed == "true" ? "-50%" : "0";
            TweenLite.to($element, 0, {
                x: direction == "right" ? -delta : delta,
                y: y,
                ease: Power2.easeOut
            });
        } else {
            //currentTop = currentTop + $('#masthead > div').height(); 

            var contOffset = $element.parent().offset().top;
            var fixedTil = $(data['fixedTil']);

            if (currentTop >= contOffset) {
                if (fixedTil.length > 0) {
                    var elementHeight = $element.outerHeight(),
                        scrollTill = (fixedTil.offset().top + fixedTil.outerHeight()) - elementHeight;

                    if (currentTop >= contOffset && currentTop < scrollTill) {
                        // var delta = currentTop - contOffset;

                        // TweenLite.to(el, 0, {
                        //     y: delta,
                        //     ease: Power2.easeOut
                        // });
                        $(el).css({
                            'position': 'fixed',
                            'top': '100px',
                            'width': $(el).parent().width()
                        });
                    } else {
                        $(el).removeAttr('style');
                    }
                }
            } else {
                $(el).removeAttr('style');
            }
        }
    });
}