function initMouseIcon() {
    var cursor = document.getElementById('js-dragger'),
        hoverElementsSelector = "#main-logo, a[href], button, button i, .slick-arrow, .slick-dots li, .marker",
        attractorElementsSelector = ".mobile-menu-button",
        lightElementsSelector = '.style-dark-bg, .style-color-jevc-bg', // quando fica branco
        darkElementsSelector = '.style-light-bg, .style-color-xsdn-bg', // quando fica preto
        backAreaElementsSelector = ".js-back-area",
        closeAreaElementsSelector = ".js-close-area",
        sliderElementsSelector = ".slick-banners.has_cursor", //".banners, .slick-slide fill",
        holdElementsSelector = ".home_intro",
        dragElementsSelector = "",
        holdArea = "",
        isHolding = false,
        mouseTimer,
        animated = false,

        mouseSettings = {
            duration: .35,
            ease: Expo.easeOut,
            isStuck: false,
        }

        pageSettings = {
            x: 100,
            y: 100,
        };

    if ($(cursor).length>0){
        initEvents();
    }

    function initEvents() {
        if(holdArea && $(holdArea).length > 0) {
            holdArea.addEventListener('mousedown', onHoldStart, false);
            holdArea.addEventListener('mouseup', onHoldEnd, false);
            holdArea.addEventListener('touchstart', onHoldStart, false);
            holdArea.addEventListener('touchend', onHoldEnd, false);
        }

        if ($('html').hasClass('touch')) return false;

        $('html').addClass('mouse-active');

        // window.addEventListener("mousemove", function(e){
        //     if (!animated) {
        //         requestAnimationFrame(function () {
        //             onMouseMove(e);
        //         });
        //         animated = true;
        //     }
        // }, false);

        window.addEventListener("mousemove", onMouseMove, false);

        $('body').on("mouseenter", hoverElementsSelector, function (e) {
            changeCursor("grow");
        });
        $('body').on("mouseleave", hoverElementsSelector, function (e) {
            changeCursor("shrink");
        });
        $('body').on("mouseenter", lightElementsSelector, function (e) {
            changeCursor("light");
        });
        $('body').on("mouseenter", darkElementsSelector, function (e) {
            changeCursor("dark");
        });
        
        if (backAreaElementsSelector && $(backAreaElementsSelector).length > 0) {
            $('body').on("mouseenter", backAreaElementsSelector, function (e) {
                changeCursor("back");
            });
            $('body').on("mouseleave", backAreaElementsSelector, function (e) {
                changeCursor("backOut");
            });
        }

        if (closeAreaElementsSelector && $(closeAreaElementsSelector).length > 0) {
            $('body').on("mouseenter", closeAreaElementsSelector, function (e) {
                changeCursor("close");
            });
            $('body').on("mouseleave", closeAreaElementsSelector, function (e) {
                changeCursor("closeOut");
            });
        }

        if (holdElementsSelector && $(holdElementsSelector).length > 0) {
            $('body').on("mouseover", holdElementsSelector, function (e) {
                if ($(e.target).closest(this).length == 1) {
                    changeCursor("hold");
                }
            });
            $('body').on("mouseleave", holdElementsSelector, function (e) {
                if ($(e.target).closest(this).length == 0) {
                    changeCursor("holdOut");
                }
            });
        }
        if (dragElementsSelector && $(dragElementsSelector).length>0){
            $('body').on("mouseenter", dragElementsSelector, function (e) {
                changeCursor("drag");
            });
            $('body').on("mouseleave", dragElementsSelector, function (e) {
                changeCursor("dragOut");
            });
        }

        if (sliderElementsSelector) {
            $('body').on("mouseenter", sliderElementsSelector, function (e) {
                changeCursor("sliderModeOn");
            });
            $('body').on("mouseleave", sliderElementsSelector, function (e) {
                changeCursor("sliderModeOff");
            });
        }            
    }
    function onMouseMove(e){
        mouseSettings.x = e.clientX;
        mouseSettings.y = e.clientY;

        TweenLite.set(cursor, {
            x: mouseSettings.x,
            y: mouseSettings.y,
            rotation: 45,
            force3D: true
        });

        animated = false;
    }
    function onHoldStart(e) {
        clearTimeout(mouseTimer);
        mouseTimer = setTimeout(function () {
            if (!isHolding) {
                isHolding = true;

                homeIntro();
            }
        }, 500);
    }
    function onHoldEnd() {
        if (isHolding) {
            isHolding = false;
            $('.glitch-transition').removeClass('visible opening');
        }

        clearTimeout(mouseTimer);
    }
    
    function changeCursor(attributes) {
        switch (attributes) {
            case "grow":
                cursor.setAttribute("data-expand", true);
                cursor.setAttribute("data-arrows", false);
                cursor.setAttribute("data-close", false);
                cursor.setAttribute("data-drag", false);
                cursor.setAttribute("data-hold", false);
                break;
            case "shrink":
                cursor.setAttribute("data-arrows", false);
                cursor.setAttribute("data-expand", false);
                cursor.setAttribute("data-close", false);
                cursor.setAttribute("data-drag", false);
                cursor.setAttribute("data-hold", false);
                break;
            case "dark":
                cursor.setAttribute("data-dark", true);
                break;
            case "light":
                cursor.setAttribute("data-dark", false);
                break;
            case "back":
                cursor.setAttribute("data-back", true);
                break;
            case "backOut":
                cursor.setAttribute("data-back", false);
                break;
            case "close":
                cursor.setAttribute("data-close", true)
                cursor.setAttribute("data-expand", true);
                break;
            case "closeOut":
                cursor.setAttribute("data-close", false);
                cursor.setAttribute("data-expand", false);
                break;
            case "drag":
                cursor.setAttribute("data-expand", true);
                cursor.setAttribute("data-arrows", true);
                cursor.setAttribute("data-drag", true);
                break;
            case "dragOut":
                cursor.setAttribute("data-expand", false);
                cursor.setAttribute("data-arrows", false);
                cursor.setAttribute("data-drag", false);
                break;
            case "hold":
                cursor.setAttribute("data-hold", true);
                break;
            case "holdOut":
                cursor.setAttribute("data-hold", false);
                break;
            case "sliderModeOn":
                cursor.setAttribute("data-drag", false);
                cursor.setAttribute("data-expand", true);
                cursor.setAttribute("data-arrows", true);
                break;
            case "sliderModeOff":
                cursor.setAttribute("data-expand", false);
                cursor.setAttribute("data-arrows", false);
                break;
            case "sliderDirection":
                var direction = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "both";
                cursor.setAttribute("data-direction", direction)
                break;
            default:
                cursor.setAttribute("data-arrows", false);
                cursor.setAttribute("data-expand", false);
        }
    }
}

function initMouseEvents(){    
    if ($cursorActive==1) initMouseIcon();
}


$(document).ready(function(){
    initMouseEvents();
});